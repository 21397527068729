<template>
  <v-dialog
    v-model="show"
    persistent
    :overlay="false"
    max-width="50%"
    transition="dialog-transition"
  >
    <v-card>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :page.sync="page"
        hide-default-header
        no-results-text="Dados não encontrados"
        :footer-props="{
          'items-per-page-text': 'Colaborador por pagina'
        }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="$emit('close')" v-bind="attrs" v-on="on" icon>
                  <v-icon color="error">mdi mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Fechar</span>
            </v-tooltip>
          </v-toolbar>
        </template>
        <template v-slot:[`footer.page-text`]="items">
          {{ items.pageStart }} de {{ items.pageStop }} Total
          {{ items.itemsLength }}
        </template>
        <template v-slot:[`item.colaborador`]="{ item }">
          {{ item.colaborador | TitleCase }}
        </template>
        <template v-slot:[`item.id_band`]="{ item }">
          <Segmento :segmento="item.id_band" />
        </template>
        <v-data-footer items-per-page-text="toa"> </v-data-footer>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  components: {
    Segmento: () => import("@/components/shared/BaseSegmento")
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: "",
      headers: [
        {
          text: "Empresa",
          value: "apelido"
        },
        {
          text: "Colaborador",
          value: "colaborador"
        },
        {
          text: "Função",
          value: "cod_funcao"
        },
        {
          text: "Descricao",
          value: "funcao"
        },
        {
          text: "Segmento",
          value: "id_band"
        }
      ]
    };
  },
  computed: {
    show() {
      return !!this.items.length > 0;
    }
  }
};
</script>
